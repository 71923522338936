import React, { useState } from "react";
import { Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import { http } from "../../helper/Config";
import "../../assets/css/login.css";

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [btntext, setBtnText] = useState("Login");
  const onFinish = async (values) => {
    setLoading(true);
    setBtnText("Please wait...");
    if (values.email === undefined || values.email === null) {
      message.error("Please enter a valid email");
      return;
    } else if (values.password === undefined || values.password === null) {
      message.error("Please enter a password");
      return;
    }

    await http.get("/sanctum/csrf-cookie");

    const response = await http.post("api/login", values);

    if (response.data.success === true) {
      setLoading(false);
      setBtnText("Login");
      localStorage.setItem("token", response.data.token);
      message.success(response.data.message);
      navigate("/dashboard");
    } else {
      setBtnText("Login");
      setLoading(false);
      message.error(response.data.message);
    }
  };

  return (
    <div className="h-screen d-flex justify-content-center align-items-center">
      <div className="w-400 card p-4">
        <h1 className="text-lg">DEMO APP - LOGIN</h1>
        <hr />
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item label="Email" name="email">
            <input type="text" />
          </Form.Item>

          <Form.Item label="Password" name="password">
            <input type="password" />
          </Form.Item>
          <div className="d-flex justify-content-between align-items-center">
            <button
              type="submit"
              className="secondary-btn mt-2"
              disabled={loading}
            >
              {loading && <i className="fas fa-spinner fa-pulse"></i>} &nbsp;
              {btntext}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Login;
