import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { http } from "../helper/Config";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SetUser } from "../redux/usersSlice";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import DefaultLayout from "./DefaultLayout";

function ProtectedRoute({ children }) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.alerts);

  const navigate = useNavigate();

  const validateToken = async () => {
    try {
      dispatch(ShowLoading());
      await http.get("/sanctum/csrf-cookie");
      const response = await http.get("api/user");

      if (response.status) {
        dispatch(HideLoading());
        dispatch(SetUser(response.data));
      } else {
        dispatch(HideLoading());
        localStorage.removeItem("token");
        navigate("/");
      }
    } catch (error) {
      dispatch(HideLoading());
      localStorage.removeItem("token");
      navigate("/");
      message.error(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      validateToken();
    } else {
      navigate("/");
    }
  }, []);
  return <div>{!loading && <DefaultLayout>{children}</DefaultLayout>}</div>;
}

export default ProtectedRoute;
