import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { http } from "../helper/Config";
import { useParams, Link } from "react-router-dom";

export default function BookingForm() {
  const { id } = useParams();
  const [formvalues, setFormValues] = useState({
    status: "",
    radiologist: "",
    timeslot: "",
    history: "",
    test_type: "",
    test_area: "",
    medicare_number: "",
    age: "",
    gender: "",
    phone_number: "",
    priority: "",
  });
  const [startDate, setStartDate] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();

    if (startDate === null) {
      toast.warning("datetime is required");
      return;
    }

    http.post(`api/updatebooking/${id}`, formvalues).then((response) => {
      if (response.data.success === true) {
        toast.success("new booking updated successfully");
      }
    });
  };

  const getBookingById = async () => {
    const res = await http.get(`api/getbookingsbyid/${id}`);

    console.log(res);

    setFormValues(res.data.data);
  };

  useEffect(() => {
    if (id) {
      getBookingById();
    }
  }, []);
  return (
    <div>
      <ToastContainer />
      <section className="content">
        <div className="container-fluid">
          <form
            id="update-bookingfrm"
            className="animate__animated animate__fadeInDown"
            onSubmit={onSubmit}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="card card-info">
                  <div class="card-header">
                    <h3 class="card-title">Booking Details</h3>
                    <div class="card-tools">
                      <Link
                        className="btn btn-primary btn-sm text-right pull-right"
                        to="/bookings"
                      >
                        <i className="fas fa-arrow-left"></i> Back to list
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            disabled={true}
                            value={formvalues.name}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="name">Medical Number</label>
                          <input
                            type="text"
                            disabled={true}
                            value={formvalues.medicare_number}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="name">Age</label>
                          <input
                            type="text"
                            disabled={true}
                            value={formvalues.age}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="name">Gender</label>
                          <input
                            type="text"
                            disabled={true}
                            value={formvalues.gender}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="name">Contact</label>
                          <input
                            type="text"
                            disabled={true}
                            value={formvalues.phone_number}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="name">History</label>
                          <input
                            type="text"
                            disabled={true}
                            value={formvalues.history}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="name">Test Type</label>
                          <input
                            type="text"
                            disabled={true}
                            value={`Test ${formvalues.test_type}`}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="name">Test Area</label>
                          <input
                            type="text"
                            disabled={true}
                            value={formvalues.test_area}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="name">Priority</label>
                          <input
                            type="text"
                            disabled={true}
                            value={formvalues.priority}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card card-info">
                  <div className="card-header">
                    <h3 className="card-title">Update Request’s status</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Status</label>
                          <select
                            onChange={(e) =>
                              setFormValues({
                                ...formvalues,
                                status: e.target.value,
                              })
                            }
                          >
                            <option value="">Select a Status</option>
                            <option
                              value="New"
                              selected={"New" === formvalues.status}
                            >
                              New
                            </option>
                            <option
                              value="Pending"
                              selected={"Pending" === formvalues.status}
                            >
                              Pending
                            </option>
                            <option
                              value="Booked"
                              selected={"Booked" === formvalues.status}
                            >
                              Booked
                            </option>
                            <option
                              value="Canceled"
                              selected={"Canceled" === formvalues.status}
                            >
                              Canceled
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Radiologist</label>
                          <select
                            onChange={(e) =>
                              setFormValues({
                                ...formvalues,
                                radiologist: e.target.value,
                              })
                            }
                          >
                            <option value="">Select a Radiologist Name</option>
                            <option
                              value="Dr. Johan"
                              selected={"Dr. Johan" === formvalues.radiologist}
                            >
                              Dr. Johan
                            </option>
                            <option
                              value="Dr. Peter"
                              selected={"Dr. Peter" === formvalues.radiologist}
                            >
                              Dr. Peter
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Timeslot</label>
                          <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={(e) => {
                              setStartDate(e);
                              setFormValues({
                                ...formvalues,
                                timeslot: e,
                              });
                            }}
                            showTimeSelect
                            dateFormat="Y-M-d h:mm aa"
                            minDate={new Date()}
                            placeholderText="Select a date"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="btn btn-block btn-success"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
