import React, { useState, useEffect } from "react";
import PageTitle from "../component/PageTitle";
import { Link } from "react-router-dom";
import { http } from "../helper/Config";
import { message } from "antd";

export default function Users() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await http.get("api/users-list");
      console.log(response);
      if (response.data.success) {
        setLoading(false);
        console.log(response.data.data);
        setUsers(response.data.data);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const onDelete = async (u) => {
    if (!window.confirm("Are you sure you want to delete?")) {
      return;
    }

    const res = await http.delete(`api/delete-user/${u.id}`);

    message.success("user deleted successfully");
    getUsers();
  };

  return (
    <div>
      <PageTitle title="Users" />

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card   animate__animated animate__fadeInDown">
                <div className="card-header">
                  <h3 className="card-title">Users List</h3>
                  <div className="card-tools">
                    <div className="input-group input-group-sm">
                      <div className="input-group-append">
                        <Link to="/users/new" className="btn btn-info">
                          Create A User
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body table-responsive p-0 ">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Created Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    {loading && (
                      <tbody>
                        <td colSpan="5" className="text-center">
                          Loading
                        </td>
                      </tbody>
                    )}
                    {!loading && (
                      <tbody>
                        {users.map((u) => (
                          <tr>
                            <td>{u.id}</td>
                            <td>{u.name}</td>
                            <td>{u.email}</td>
                            <td>{u.created_at}</td>
                            <td>
                              <Link
                                className="btn btn-warning btn-sm"
                                to={"/users/" + u.id}
                              >
                                <i className="fas fa-edit"></i> Edit
                              </Link>
                              &nbsp;
                              <button
                                onClick={(e) => onDelete(u)}
                                className="btn btn-danger btn-sm"
                              >
                                <i className="fas fa-trash"></i> Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
