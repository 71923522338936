import { useSelector } from "react-redux";
import { http } from "../helper/Config";
import { message } from "antd";
import { Link, useNavigate } from "react-router-dom";

export default function Sidenav() {
  const { user } = useSelector((state) => state.users);
  const navigate = useNavigate();
  const logOut = async () => {
    try {
      const response = await http.post("/api/logout");

      if (response.status) {
        localStorage.removeItem("token");
        navigate("/");
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  return (
    <div>
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="info">
              <Link to="/dashboard" className="d-block">
                Welcome {user?.is_admin ? "Admin" : "Receptionist"}
              </Link>
            </div>
          </div>

          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {user?.is_admin != 1 && (
                <li className="nav-item">
                  <Link to="/bookings" className="nav-link">
                    <i className="nav-icon fas fa-th" />
                    Bookings
                  </Link>
                </li>
              )}
              {user?.is_admin == 1 && (
                <li className="nav-item">
                  <Link to="/bookingslist" className="nav-link">
                    <i className="nav-icon fas fa-th" />
                    Bookings List
                  </Link>
                </li>
              )}

              {user?.is_admin == 1 && (
                <li className="nav-item">
                  <Link to="/users" className="nav-link">
                    <i className="nav-icon fas fa-users" />
                    Users
                  </Link>
                </li>
              )}

              <li
                className="nav-item"
                onClick={() => {
                  logOut();
                }}
              >
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-arrow-right" />
                  <p>Logout</p>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
}
