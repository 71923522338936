import React from "react";
import PageTitle from "../component/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { Table, message } from "antd";
import { http } from "../helper/Config";
import BookingForm from "../component/BookingForm";
import { Link } from "react-router-dom";

function Dashboard() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  return (
    <div>
      <PageTitle title="Dashboard"></PageTitle>
    </div>
  );
}

export default Dashboard;
