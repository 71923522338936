import React, { useState } from "react";
import PageTitle from "../component/PageTitle";
import { http } from "../helper/Config";
import { message } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Bookings() {
  const { user } = useSelector((state) => state.users);
  const [formvalues, setFormValues] = useState({
    name: "",
    medicare_number: "",
    age: "",
    gender: "",
    phone_number: "",
    history: "",
    test_type: "",
    test_area: "",
    priority: "",
  });
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const [bookings, setBookings] = React.useState([]);

  const getBookings = async () => {
    try {
      const response = await http.get("api/bookings");

      if (response.data.success) {
        setBookings(response.data.data);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  React.useEffect(() => {
    getBookings();
  }, []);
  return (
    <div>
      <ToastContainer />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card   animate__animated animate__fadeInUp">
                <div className="card-header">
                  <h3 className="card-title">Booking List</h3>
                </div>
                <div className="card-body table-responsive p-0 ">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Medical No</th>
                        <th>Age</th>
                        <th>Gender</th>
                        <th>Contact</th>
                        <th>Status</th>
                        <th>Priority</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {loading && (
                      <tbody>
                        <td colSpan="8" className="text-center">
                          Loading
                        </td>
                      </tbody>
                    )}
                    {!loading && (
                      <tbody>
                        {bookings.map((u) => (
                          <tr>
                            <td>{u.name}</td>
                            <td>{u.medicare_number}</td>
                            <td>{u.age}</td>
                            <td>{u.gender}</td>
                            <td>{u.phone_number}</td>
                            <td>
                              <span className="badge badge-info">
                                {u.status}
                              </span>
                            </td>
                            <td>
                              <span className="badge badge-success">
                                {u.priority}
                              </span>
                            </td>
                            <td>
                              <Link
                                className="btn btn-warning btn-sm"
                                to={"/update_request/" + u.id}
                              >
                                <i className="fas fa-crosshairs"></i> Update
                                Request
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
