import React from "react";
import "../assets/css/layout.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import Sidenav from "./Sidenav";

function DefaultLayout({ children }) {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = React.useState(false);
  const { user } = useSelector((state) => state.users);

  const userMenu = [
    {
      name: "Home",
      icon: "ri-home-line",
      path: "/dashboard",
    },

    {
      name: "Logout",
      icon: "ri-logout-circle-line",
      path: "/logout",
    },
  ];
  const adminMenu = [
    {
      name: "Home",
      path: "/dashboard",
      icon: "ri-home-line",
    },

    {
      name: "Logout",
      path: "/logout",
      icon: "ri-logout-circle-line",
    },
  ];
  const menuToBeRender = user?.is_admin ? adminMenu : userMenu;

  let activeRoute = window.location.pathname;
  if (window.location.pathname.includes("/book-now")) {
    activeRoute = "/";
  }

  return (
    <>
      <Header />

      <div className="content-wrapper">{children}</div>

      <Sidenav />

      <Footer />
    </>
  );
}

export default DefaultLayout;
