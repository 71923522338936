import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { http } from "../helper/Config";
import { message } from "antd";

export default function UserForm() {
  const { id } = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [user, setUser] = useState({
    id: null,
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (user.id) {
      http.post(`api/users/${user.id}`, user).then((data) => {
        message.success("User was successfully update");
        navigate("/users");
      });
    } else {
      http
        .post(`api/newuser`, user)
        .then(() => {
          message.success("User was successfully created");
          navigate("/users");
        })
        .catch((err) => {
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors);
          }
        });
    }
  };

  const getUserById = async () => {
    const res = await http.get(`api/users/${id}`);

    setUser(res.data.data);
  };

  useEffect(() => {
    setLoading(false);
    if (id) {
      getUserById();
    }
  }, []);

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 animate__animated animate__fadeInDown">
              {user.id && <h1>Update User: {user.name}</h1>}
              {!user.id && <h1>New User</h1>}
              <div className="card animated fadeInDown">
                {loading && <div className="text-center">Loading...</div>}
                {errors && (
                  <div className="badge badge-danger alert-danger">
                    {Object.keys(errors).map((key) => (
                      <p key={key}>{errors[key][0]}</p>
                    ))}
                  </div>
                )}
                {!loading && (
                  <form onSubmit={onSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          value={user.name}
                          placeholder="Enter name"
                          onChange={(e) =>
                            setUser({ ...user, name: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="name">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          value={user.email}
                          placeholder="Enter email"
                          onChange={(e) =>
                            setUser({ ...user, email: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="name">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          id="password"
                          value={user.password}
                          placeholder="Enter password"
                          onChange={(e) =>
                            setUser({ ...user, password: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="name">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="password_confirmation"
                          id="password_confirmation"
                          value={user.password_confirmation}
                          placeholder="Enter Password Confirmation"
                          onChange={(e) =>
                            setUser({
                              ...user,
                              password_confirmation: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        SAVE
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
