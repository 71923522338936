import React, { useState } from "react";
import PageTitle from "../component/PageTitle";
import { http } from "../helper/Config";
import { message } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ManageBookings() {
  const { user } = useSelector((state) => state.users);
  const [formvalues, setFormValues] = useState({
    name: "",
    medicare_number: "",
    age: "",
    gender: "",
    phone_number: "",
    history: "",
    test_type: "",
    test_area: "",
    priority: "",
  });
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const [bookings, setBookings] = React.useState([]);

  const getBookings = async () => {
    try {
      const response = await http.get("api/bookings");

      if (response.data.success) {
        setBookings(response.data.data);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  React.useEffect(() => {
    getBookings();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      if (formvalues.name === undefined || formvalues.name === "") {
        toast.warning("name is required");
        return;
      } else if (formvalues.gender === undefined || formvalues.gender === "") {
        toast.warning("gender is required");
        return;
      } else if (
        formvalues.history === undefined ||
        formvalues.history === ""
      ) {
        toast.warning("history is required");
        return;
      } else if (
        formvalues.medicare_number === undefined ||
        formvalues.medicare_number === ""
      ) {
        toast.warning("medicare number is required");
        return;
      } else if (
        formvalues.phone_number === undefined ||
        formvalues.phone_number === ""
      ) {
        toast.warning("phone is required");
        return;
      } else if (
        formvalues.priority === undefined ||
        formvalues.priority === ""
      ) {
        toast.warning("Priority is required");
        return;
      } else if (
        formvalues.test_area === undefined ||
        formvalues.test_area === ""
      ) {
        message.warning("Test Area is required");
        return;
      } else if (
        formvalues.test_type === undefined ||
        formvalues.test_type === ""
      ) {
        message.warning("Test Type is required");
        return;
      }

      const response = await http.post("api/addbooking", formvalues);

      if (response.data.success === true) {
        toast.success("new booking added successfully");
        document.getElementById("make-bookingfrm").reset();
        getBookings();
      } else {
        setErrors(response.data.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <ToastContainer />
      <section className="content">
        <div className="container-fluid">
          <form
            onSubmit={onSubmit}
            id="make-bookingfrm"
            className="animate__animated animate__fadeInDown"
          >
            <div className="row">
              {errors && (
                <div className="badge badge-danger alert-danger">
                  {Object.keys(errors).map((key) => (
                    <p key={key}>{errors[key][0]}</p>
                  ))}
                </div>
              )}
              <div className="col-md-7">
                <div className="card card-info">
                  <div className="card-header">
                    <h3 className="card-title">Patient’s Personal Details</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Name"
                            onChange={(e) =>
                              setFormValues({
                                ...formvalues,
                                name: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Medical Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Medical Number"
                            onChange={(e) =>
                              setFormValues({
                                ...formvalues,
                                medicare_number: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Age</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Age"
                            onChange={(e) =>
                              setFormValues({
                                ...formvalues,
                                age: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Gender</label>
                          <select
                            onChange={(e) =>
                              setFormValues({
                                ...formvalues,
                                gender: e.target.value,
                              })
                            }
                          >
                            <option value="">Select a Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Contact No</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Contact"
                            onChange={(e) =>
                              setFormValues({
                                ...formvalues,
                                phone_number: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="card card-info">
                  <div className="card-header">
                    <h3 className="card-title">Patient’s Medical Details</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>History/Symptoms</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter History/Symptoms"
                            onChange={(e) =>
                              setFormValues({
                                ...formvalues,
                                history: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Test type requested</label>
                          <select
                            onChange={(e) =>
                              setFormValues({
                                ...formvalues,
                                test_type: e.target.value,
                              })
                            }
                          >
                            <option value="">Select a Type</option>
                            <option value="1">Type 1</option>
                            <option value="2">Type 2</option>
                            <option value="3">Type 3</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Test Area</label>
                          <select
                            onChange={(e) =>
                              setFormValues({
                                ...formvalues,
                                test_area: e.target.value,
                              })
                            }
                          >
                            <option value="">Select a Area</option>
                            <option value="Area 1">Area 1</option>
                            <option value="Area 2">Area 2</option>
                            <option value="Area 3">Area 3</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Priority</label>
                          <select
                            onChange={(e) =>
                              setFormValues({
                                ...formvalues,
                                priority: e.target.value,
                              })
                            }
                          >
                            <option value="">Select a Priority</option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <button type="submit" class="btn btn-info btn-block">
                          Create Booking
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="row">
            <div className="col-12">
              <div className="card   animate__animated animate__fadeInUp">
                <div className="card-header">
                  <h3 className="card-title">Booking List</h3>
                </div>
                <div className="card-body table-responsive p-0 ">
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Medical No</th>
                        <th>Age</th>
                        <th>Gender</th>
                        <th>Contact</th>
                        <th>History</th>
                        <th>T(Type)</th>
                        <th>T(Area)</th>
                        <th>Priority</th>
                        <th>Status</th>
                        <th>Radiologist</th>
                        <th>Timeslot</th>
                      </tr>
                    </thead>
                    {loading && (
                      <tbody>
                        <td colSpan="10" className="text-center">
                          Loading
                        </td>
                      </tbody>
                    )}
                    {!loading && (
                      <tbody>
                        {bookings.map((u) => (
                          <tr>
                            <td>{u.name}</td>
                            <td>{u.medicare_number}</td>
                            <td>{u.age}</td>
                            <td>{u.gender}</td>
                            <td>{u.phone_number}</td>
                            <td>{u.history}</td>
                            <td>{u.test_type}</td>
                            <td>{u.test_area}</td>
                            <td>{u.priority}</td>
                            <td>
                              <span className="badge badge-info">
                                {u.status}
                              </span>
                            </td>
                            <td>{u.radiologist}</td>
                            <td>{u.timeslot}</td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
